import React from "react";
import { StaticQuery, graphql } from "gatsby";
import './competences.scss';

const cpName = "competences";

const Competences = ({ data }) => (
    <React.Fragment>
        <h2>
            Skills
        </h2>

        <ul className={cpName + "-list"}>
            <li className={cpName + "-item"}>
                <p className={cpName + "-title"}>
                    Langages and libraries
                </p>

                <p className={cpName + "-value"}>
                    JavaScript, React, Redux, Vue 3, Nuxt, GraphQL, CI/CD, Git, Ethers.js, Wagmi...
                </p>
            </li>

            <li className={cpName + "-item"}>
                <p className={cpName + "-title"}>
                    Knowledges
                </p>

                <p className={cpName + "-value"}>
                    Gatsby, Strapi, Prisma, Apollo, Styled-components, SASS...
                </p>
            </li>

            <li className={cpName + "-item"}>
                <p className={cpName + "-title"}>
                    Open Source
                </p>

                <p className={cpName + "-value"}>
                    Worked 3 years as an Open Source maintainer at PrestaShop, I did some english demos and I'm also a ShapeShift contributor.
                </p>
            </li>
        </ul>
    </React.Fragment>
)

export default Competences
